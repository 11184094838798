import React from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import {
  formatDateToDisplayWithHour,
  formatDateToDisplay
} from "../../util/formatters";
import { COMPANY } from "../../util/Masks";
// enums
import { documentTypesEnum, uploadDocumentForm } from "../../util/Enums";
// options
import { transportModal } from "../../util/Options";
import { LabelField, InputCurrency } from "../index";

const getModalLabel = (modal) => {
  if (modal) {
    const labels = transportModal.filter((item) => item.value === modal);
    return labels.length > 0 ? labels[0].label : null;
  }
  return null;
};
const labelSize = 157;

const RegisterViewer = ({ registerData }) => {
  const { t: translation } = useTranslation();
  return (
    <>
      <Row>
        <Col md={8} sm={24} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("registerViewer.cnpj")}
          >
            <NumberFormat
              displayType="text"
              value={registerData.company}
              format={COMPANY}
            />
          </LabelField>
        </Col>
      </Row>
      <Row>
        <Col lg={7} sm={24} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation(
              `registerViewer.documentNumber.${registerData.documentType}`
            )}
          >
            <span>{registerData.documentNumber}</span>
          </LabelField>
        </Col>
        {registerData.documentType !== documentTypesEnum.OTHERS ? (
          <Col lg={7} sm={24} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation(
                `registerViewer.sequential.${registerData.documentType}`
              )}
            >
              <span>{registerData.sequential}</span>
            </LabelField>
          </Col>
        ) : null}
        <Col lg={8} sm={24} className="gx-p-0">
          <LabelField
            labelWidth={175}
            label={translation("registerViewer.registerAt")}
          >
            <span>{formatDateToDisplayWithHour(registerData.issuedAt)}</span>
          </LabelField>
        </Col>
      </Row>
      {registerData.documentType === documentTypesEnum.OTHERS ? (
        <Row>
          <Col md={8} sm={24} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("registerViewer.modalType")}
            >
              <span>{getModalLabel(registerData.modal)}</span>
            </LabelField>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={8} sm={24} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("registerViewer.accessKey")}
            >
              <span>{registerData.accessKey}</span>
            </LabelField>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={8} sm={24} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("registerViewer.origin")}
          >
            <span>{registerData.origin}</span>
          </LabelField>
        </Col>
      </Row>
      <Row>
        <Col md={8} sm={24} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("registerViewer.destination")}
          >
            <span>{registerData.destination}</span>
          </LabelField>
        </Col>
      </Row>
      <Row>
        <Col md={8} sm={24} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("registerViewer.mainMerchandise")}
          >
            <span>{registerData.mainMerchandise}</span>
          </LabelField>
        </Col>
      </Row>
      <Row>
        <Col md={8} sm={24} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("registerViewer.merchandiseValue")}
          >
            <span>
              <InputCurrency
                value={registerData.merchandiseValue}
                displayType="text"
              />
            </span>
          </LabelField>
        </Col>
      </Row>
      {registerData.documentType === documentTypesEnum.OTHERS ? (
        <Row>
          <Col md={8} sm={24} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("register.manually.boardingAtLabel")}
            >
              <span>{formatDateToDisplay(registerData.boardingAt)}</span>
            </LabelField>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col md={8} sm={24} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("registerViewer.vehicleIdentification")}
          >
            <span>{registerData.vehicleIdentification}</span>
          </LabelField>
        </Col>
      </Row>
      <Row>
        <Col md={8} sm={24} className="gx-p-0">
          <LabelField
            labelWidth={labelSize}
            label={translation("registerViewer.uploadFromLabel")}
          >
            <span>{uploadDocumentForm[registerData.uploadedFrom] || ""}</span>
          </LabelField>
        </Col>
      </Row>
      {registerData.cancelReason && (
        <Row>
          <Col md={24} lg={12} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("cancel.manually.cancelReasonLabel")}
            >
              <span>{registerData.cancelReason}</span>
            </LabelField>
          </Col>
        </Row>
      )}
      {registerData.cancelRefuseReason && (
        <Row>
          <Col md={24} lg={12} className="gx-p-0">
            <LabelField
              labelWidth={labelSize}
              label={translation("cancel.manually.cancelRefuseReasonLabel")}
            >
              <span>{registerData.cancelRefuseReason}</span>
            </LabelField>
          </Col>
        </Row>
      )}
    </>
  );
};

export default RegisterViewer;
